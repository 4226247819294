import React from 'react'
import Header from '../../Menabout/Header'
import Bredcom from '../../Bredcom/Main'
import Singleblog from '../../Blog/Singleblog'
import Singleblogone from '../../Blog/Singleblogone'

function Main() {
  return (
    <>
        <div className="inner_page_block">
          <Header/>
          <Bredcom title={"Home"} subtitle={"Blog single"} same={"Blog single"}/>
        </div>
        
        <Singleblogone/>
        <Singleblog/>

    </>
  )
}

export default Main

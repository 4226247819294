import React from 'react'
import Header from '../../Menabout/Header'
import Fourthone from '../../List/Fourthone'
import Double from '../../List/Double'
import Bredcom from '../../Bredcom/Second'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom title={"Home"} subtitle={"Services"} same={"Software Development"}/>
      </div>


      <Fourthone />
      <Double />


    </>
  )
}

export default Main

import React from 'react'
import { Link } from 'react-router-dom'
// import banner1 from '../../assets/images/new/banner_01.png'
import banner1 from '../../assets/images/custom/home-b1.png'
import banner2 from '../../assets/images/new/banner_02.png'
import banner3 from '../../assets/images/new/banner_03.png'
import banner4 from '../../assets/images/new/banner_04.png'
import Icon1 from '../../assets/images/new/plus.svg'
import Icon2 from '../../assets/images/new/polygon.svg'
import Icon3 from '../../assets/images/new/round.svg'

function Banner() {
  return (
    <>
      <div className="banner_shapes">
        <div className="container">
          <span>
            <img src={Icon1} alt="image" />
          </span>
          <span>
            <img src={Icon2} alt="image" />
          </span>
          <span>
            <img src={Icon3} alt="image" />
          </span>
        </div>
      </div>

      <section className="banner_section">
        <div className="container">
          <div className="banner_text">
            <div
              className="ban_inner_text"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <h1>The Company with endless posibilities</h1>
              {/* <span>From Devices to Software Solutions, We provide it all.</span> */}

              {/* <p>
                The World's simplest and fast platform that brings everything
                together
              </p> */}
            </div>
            {/* <form action="submit" data-aos="fade-up" data-aos-duration={1500}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                />
                <button className="btn btn_main">
                  TRY FOR FREE <i className="icofont-arrow-right" />
                </button>
              </div>
              <span>
                Already using software? <Link to="#">Sign in</Link>
              </span>
            </form> */}
          </div>
          <div
            className="banner_images"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <img src={'https://abouttmc.com/wp-content/uploads/2016/06/ERP-Software-Solution-%E2%80%93-Provides-Your-Team-With-Real-Time-Access-to-Vital-Data.jpg'} alt="image" width={'100%'} />
            {/* <div className="sub_images">
              <img className="moving_animation" src={banner2} alt="image" />
              <img className="moving_animation" src={banner3} alt="image" />
              <img className="moving_animation" src={banner4} alt="image" />
            </div> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner

import React from 'react'
import One from '../../assets/images/new/google.png'
import Two from '../../assets/images/new/clutch.png'
import Three from '../../assets/images/new/capterra.png'

const reviews = [
  {
    icon: One,
    mainRating: '4.9',
    totalReview: '485 Reviews'
  },
  {
    icon: Two,
    mainRating: '4.8',
    totalReview: '390 Reviews'
  },
  {
    icon: Three,
    mainRating: '4.6',
    totalReview: '276 Reviews'
  }
]

function Service() {
  return (
    <section className="review_section">
      <div className="container">
        <div className="row">
          {reviews.map((review, index) => (
            <div className="col-lg-4" key={index}>
              <div className="review_box" data-aos="fade-up" data-aos-duration={2000}>
                <div className="icon">
                  <img src={review.icon} alt="image" />
                </div>
                <div className="rating">
                  {[...Array(5)].map((_, i) => (
                    <span key={i}>
                      <i className="icofont-star" />
                    </span>
                  ))}
                </div>
                <div className="text">
                  <h3>
                    <span className="main_rating">{review.mainRating}</span> out of 5 Based on
                    <span className="total_review">{review.totalReview}</span>
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Service

import React from 'react'
import Unique from '../Menhome/Unique'
import Data from '../Menhome/Data'
import Securely from '../Menhome/Securely'
import Communication from '../Menhome/Communication'
import Counter from '../Menhome/Counter'
import Integration from '../Menhome/Integration'
import Customer from '../Menhome/Customer'
import Trusted from '../Menhome/Trusted'
import Pricing from '../Menhome/Pricing'
import Support from '../Menhome/Support'
import Overview from '../Menhome/Overview'
import Freetrial from '../Menhome/Freetrial'
import Faq from '../Menhome/Faq'
import Story from '../Menhome/Story'
import Header from '../Header/Main'
import Banner from '../Menhome/Banner'
import ApprovedBy from '../Menhome/ApprovedBy'
import Bredcom from '../Bredcom/Main'
import Service from '../Menabout/Service'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        {/* <Banner /> */}
        <Bredcom same={"Our Services"}/>

      </div>
      <Service/>

      {/* <Unique /> */}
      {/* <Data /> */}
      {/* <Securely /> */}
      {/* <Communication /> */}
      {/* <Counter /> */}
      <Integration />
      {/* <Customer /> */}
      <Trusted />
      <ApprovedBy />

      {/* <Story /> */}
    </>
  )
}

export default Main

import React from 'react'
import Bloglist from '../../Blog/Bloglist'
import Header from '../../Menabout/Header'
import Bredcom from '../../Bredcom/Main'
import Pricing from '../../Menhome/Pricing'
import Support from '../../Menhome/Support'
import Faq from '../../Menhome/Faq'

function Main() {
  return (
    <div className='vh'>
      <div className="inner_page_block">
        <Header />
        <Bredcom
          title={'Home'}
          subtitle={'Safety GPS Tracker Service'}
          same={'Safety GPS Tracker Service'}
        />
      </div>

      {/* <Bloglist/> */}
      <Pricing />
      <Support />
      {/* <Overview /> */}
      {/* <Freetrial /> */}
      <Faq />
    </div>
  )
}

export default Main

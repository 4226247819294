import React from 'react'
import One from '../../assets/images/new/service_coustomer_01.png'
import Two from '../../assets/images/new/service_coustomer_02.png'
import Three from '../../assets/images/new/service_coustomer_01.png'
import Four from '../../assets/images/new/service_coustomer_02.png'
import Five from '../../assets/images/new/service_coustomer_01.png'
import Six from '../../assets/images/new/service_coustomer_02.png'

function Customer() {
  return (
    <>
      <section className="service_review_section">
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
            <h2>We work with customers worldwide.</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typese tting indus
              orem Ipsum has beenthe standard dummy text ever since.
            </p>
          </div>
          <div className="review_pannel">
            <div className="review_block" data-aos="fade-up" data-aos-duration={1500}>
              <p>
                <b>“ Quick Support &amp; Great Design!</b> Lorem Ipsum is simply dummy
                text of the printing and typesetting industry lorem Ipsum has been the
                industrys standard dummy text ever since the when an unknown printer
                took.”
              </p>
              <div className="avtar_block">
                <div className="avtar">
                  <div className="img">
                    <img src={One} alt="image" />
                  </div>
                  <div className="text">
                    <h3>John Doe</h3>
                    <span>Technosoft Pvt Ltd.</span>
                  </div>
                </div>
                <div className="rating">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
              </div>
            </div>
            <div className="review_block" data-aos="fade-up" data-aos-duration={1500}>
              <p>
                <b>“ Bug FREE Coding &amp; Easy Documentation!</b> Printing and
                typesetting industry lorem Ipsum has been the industrys standard dummy
                text ever since the when orem Ipsum is simply dummy text of the .”
              </p>
              <div className="avtar_block">
                <div className="avtar">
                  <div className="img">
                    <img src={Two} alt="image" />
                  </div>
                  <div className="text">
                    <h3>Shaily Zill</h3>
                    <span>Envato Corp</span>
                  </div>
                </div>
                <div className="rating">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
              </div>
            </div>
            <div className="review_block" data-aos="fade-up" data-aos-duration={1500}>
              <p>
                <b>“ Excellence Team!</b> Lorem Ipsum is simply dummy text of the
                printing and typesetting industry lorem Ipsum has been the industrys
                standard dummy text ever since the when an unknown printer took.”
              </p>
              <div className="avtar_block">
                <div className="avtar">
                  <div className="img">
                    <img src={Three} alt="image" />
                  </div>
                  <div className="text">
                    <h3>Sophiya Zaa</h3>
                    <span>Mindwebly</span>
                  </div>
                </div>
                <div className="rating">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
              </div>
            </div>
            <div className="review_block" data-aos="fade-up" data-aos-duration={1500}>
              <p>
                <b>“ Appriciate and Recommend Services!!</b> Printing and typesetting
                industry lorem Ipsum has been the industrys standard dummy text ever
                since the when orem Ipsum is simply dummy text of the.”
              </p>
              <div className="avtar_block">
                <div className="avtar">
                  <div className="img">
                    <img src={Four} alt="image" />
                  </div>
                  <div className="text">
                    <h3>Tom Cruzz </h3>
                    <span>Smart Webly</span>
                  </div>
                </div>
                <div className="rating">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
              </div>
            </div>
            <div className="review_block" data-aos="fade-up" data-aos-duration={1500}>
              <p>
                <b>“ Quick Support &amp; Great Design!</b> Lorem Ipsum is simply dummy
                text of the printing and typesetting industry lorem Ipsum has been the
                industrys standard dummy text ever since the when an unknown printer
                took.”
              </p>
              <div className="avtar_block">
                <div className="avtar">
                  <div className="img">
                    <img src={Five} alt="image" />
                  </div>
                  <div className="text">
                    <h3>John Doe</h3>
                    <span>Technosoft Pvt Ltd.</span>
                  </div>
                </div>
                <div className="rating">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
              </div>
            </div>
            <div className="review_block" data-aos="fade-up" data-aos-duration={1500}>
              <p>
                <b>“ Bug FREE Coding &amp; Easy Documentation!</b> Printing and
                typesetting industry lorem Ipsum has been the industrys standard dummy
                text ever since the when orem Ipsum is simply dummy text of the .”
              </p>
              <div className="avtar_block">
                <div className="avtar">
                  <div className="img">
                    <img src={Six} alt="image" />
                  </div>
                  <div className="text">
                    <h3>Shaily Zill</h3>
                    <span>Envato Corp</span>
                  </div>
                </div>
                <div className="rating">
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                  <span>
                    <i className="icofont-star" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Customer

import React from 'react'
import one from '../../assets/images/new/business_vectore.png'
import two from '../../assets/images/new/Powerful-solution-01.png'
import three from '../../assets/images/new/Powerful-solution-02.png'
import four from '../../assets/images/new/Powerful-solution-03.png'
import five from '../../assets/images/new/Powerful-solution-04.png'
import six from '../../assets/images/new/Powerful-solution-05.png'
import seven from '../../assets/images/new/Powerful-solution-06.png'
import eight from '../../assets/images/new/Powerful-solution-07.png'
import nine from '../../assets/images/new/Powerful-solution-08.png'
import ten from '../../assets/images/new/Powerful-solution-09.png'
import NumberCounter from 'number-counter'


function Counter() {
    return (
        <>
            <section className="powerful_solution" data-aos="fade-in" data-aos-duration={1000} >
                <div className="dotes_anim_bloack">
                    <div className="dots dotes_1" />
                    <div className="dots dotes_2" />
                    <div className="dots dotes_3" />
                    <div className="dots dotes_4" />
                    <div className="dots dotes_5" />
                    <div className="dots dotes_6" />
                    <div className="dots dotes_7" />
                    <div className="dots dotes_8" />
                </div>
                <div className="bg_pattern">
                    <img src={one} alt="image" />
                </div>
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1000}>
                        <h2>Powerful solution for your business</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typese tting indus
                            orem Ipsum has beenthe standard dummy text ever since.
                        </p>
                    </div>
                    <div className="quality_lable" data-aos="fade-up" data-aos-duration={1000}>
                        <ul>
                            <li>
                                <p>
                                    <i className="icofont-check-circled" />
                                    Highly customizable
                                </p>
                            </li>
                            <li>
                                <p>
                                    <i className="icofont-check-circled" />
                                    Pixel perfect design
                                </p>
                            </li>
                            <li>
                                <p>
                                    <i className="icofont-check-circled" />
                                    Worldwide support
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="counters_block" data-aos="fade-up" data-aos-duration={1000}>
                        <ul
                            className="app_statstic"
                            id="counter"
                            data-aos="fade-in"
                            data-aos-duration={1500}
                        >
                            <li>
                                <div className="text">
                                    <p className="d-flex justify-content-center">
                                        <NumberCounter className="counter-value" data-count={120} end={450} delay={0.5}>
                                            0
                                        </NumberCounter>
                                        <span>+</span>
                                    </p>
                                    <p>Million of client logins monthly</p>
                                </div>
                            </li>
                            <li>
                                <div className="text">
                                    <p className="d-flex justify-content-center">
                                        <NumberCounter className="counter-value" data-count={120} end={120} delay={2}>
                                            0{" "}
                                        </NumberCounter>
                                        <span>+</span>
                                    </p>
                                    <p>Languages and countries</p>
                                </div>
                            </li>
                            <li>
                                <div className="text">
                                    <p  className="d-flex justify-content-center">
                                        <NumberCounter className="counter-value" data-count={120} end={135} delay={2}>
                                            0{" "}
                                        </NumberCounter>
                                        <span>+</span>
                                    </p>
                                    <p>Percent yearly turnover increase</p>
                                </div>
                            </li>
                            <li>
                                <div className="text">
                                    <p  className="d-flex justify-content-center">
                                        <NumberCounter className="counter-value" data-count={120} end={324} delay={1}>
                                            0{" "}
                                        </NumberCounter>
                                       
                                        <span>+</span>
                                    </p>
                                    <p>Million active accounts</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="images_gallery_block row">
                        <div
                            className="gl_block col-md-3 col-sm-6"
                            data-aos="fade-up"
                            data-aos-duration={1000}>
                            <div className="img">
                                <img src={two} alt="image" />
                            </div>
                            <div className="img">
                                <img src={three} alt="image" />
                            </div>
                        </div>
                        <div
                            className="gl_block col-md-3 col-sm-6"
                            data-aos="fade-up"
                            data-aos-duration={1000}
                            data-aos-delay={100}
                        >
                            <div className="img">
                                <img src={four} alt="image" />
                            </div>
                            <div className="img">
                                <img src={five} alt="image" />
                            </div>
                            <div className="img">
                                <img src={six} alt="image" />
                            </div>
                        </div>
                        <div
                            className="gl_block col-md-3 col-sm-6"
                            data-aos="fade-up"
                            data-aos-duration={1000}
                            data-aos-delay={150}
                        >
                            <div className="img">
                                <img src={seven} alt="image" />
                            </div>
                            <div className="img">
                                <img src={eight} alt="image" />
                            </div>
                        </div>
                        <div
                            className="gl_block col-md-3 col-sm-6"
                            data-aos="fade-up"
                            data-aos-duration={1000}
                            data-aos-delay={200}
                        >
                            <div className="img">
                                <img src={nine} alt="image" />
                            </div>
                            <div className="img">
                                <img src={ten} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Counter

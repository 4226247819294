import React from 'react'
import { Link } from 'react-router-dom'
import One from '../../assets/images/new/lostman.png'
import Two from '../../assets/images/new/error-number.png'


function Error404() {
  return (
    <>
      <div className="error_page_bg">
        <div className="container">
          <section className="error_page_section">
            <div className="section_title">
              <h2>Oops! Page Not Found, something went wrong</h2>
            </div>
            <div className="error_graphic">
              <div className="lostman">
                <img src={One} alt="image" />
              </div>
              <div className="errornumber">
                <img src={Two} alt="image" />
              </div>
            </div>
            <div className="top_part">
              <Link
                to="/"
                data-aos="fade-up"
                data-aos-duration={2000}
                className="btn btn_main aos-init aos-animate"
              >
                <i className="icofont-arrow-left" /> Back to Home
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Error404

import React, { useState } from 'react'
import Eleven from '../../assets/images/new/Free-Trial-01.svg'
import Twelve from '../../assets/images/new/unlimited.png'
import Thirteen from '../../assets/images/new/Premium.svg'
import Forteen from '../../assets/images/new/Free-Trial-01.svg'
import Fifteen from '../../assets/images/new/unlimited.png'
import Sixteen from '../../assets/images/new/Premium.svg'
import { Link } from 'react-router-dom'


function Doublethree() {

    const [active, setActive] = useState(false)

    return (
        <>
            <section className="row_am pricing_section service_list_ps"
                id="pricing"
                data-aos="fade-in"
                data-aos-duration={1000} >

                <div className="container">
                    <div className="section_title" data-aos="fade-up"
                        data-aos-duration={1500}
                        data-aos-delay={300}>
                        <h2>Best plans, pay what you use</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typese tting
                            indus <br /> orem Ipsum has beenthe standard dummy text ever since.
                        </p>
                    </div>

                    <div className="toggle_block" data-aos="fade-up" data-aos-duration={1500}>
                        <span className={`month ${!active && "active"}`}>Monthly</span>
                        <div className="tog_block" onClick={() => {
                            setActive(!active)
                        }}>
                            <span className={`tog_btn ${active && "month_active"}`} />
                        </div>
                        <span className={`years ${active && "active"}`}>Yearly</span>
                        <span className="offer">50% off</span>
                    </div>

                    <div
                        className="pricing_pannel monthly_plan active"
                        data-aos="fade-up"
                        data-aos-duration={1500}
                    >

                        <div className="row">

                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                        <img src={Eleven} alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Free Trial</h3>
                                        <span>For the basics</span>
                                    </div>
                                    <span className="price">$0<span>/Month</span>
                                    </span>
                                    <ul className="benifits">
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />7 Days free trial
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />2 platform of your
                                                choice
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                10 GB Dedicated Hosting free
                                            </p>
                                        </li>
                                        <li className="exclude">
                                            <p>
                                                <i className="icofont-close-circled" />
                                                Unlimited updates
                                            </p>
                                        </li>
                                        <li className="exclude">
                                            <p>
                                                <i className="icofont-close-circled" />
                                                Live support
                                            </p>
                                        </li>
                                    </ul>
                                    <Link to="#" className="btn btn_main">
                                        BUY NOW <i className="icofont-arrow-right" />
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="pricing_block highlited_block">
                                    <div className="icon">
                                        <img src={Twelve} alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Unlimited</h3>
                                        <span>For the professionals</span>
                                    </div>
                                    <span className="price">${active ? 999 : 99}<span>/Month</span>
                                    </span>
                                    <ul className="benifits">
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />7 Days free trial
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />2 platform of your
                                                choice
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                10 GB Dedicated Hosting free
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                Unlimited updates
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                Live support
                                            </p>
                                        </li>
                                    </ul>
                                    <Link to="#" className="btn btn_main">
                                        BUY NOW <i className="icofont-arrow-right" />
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                        <img src={Thirteen} alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Premium</h3>
                                        <span>For small team</span>
                                    </div>
                                    <span className="price">${active ? 555 : 55}<span>/Month</span>
                                    </span>
                                    <ul className="benifits">
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />7 Days free trial
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />2 platform of your
                                                choice
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                10 GB Dedicated Hosting free
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                Unlimited updates
                                            </p>
                                        </li>
                                        <li className="exclude">
                                            <p>
                                                <i className="icofont-close-circled" />
                                                Live support
                                            </p>
                                        </li>
                                    </ul>
                                    <Link to="#" className="btn btn_main">
                                        BUY NOW <i className="icofont-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div
                        className="pricing_pannel yearly_plan"
                        data-aos="fade-up"
                        data-aos-duration={1500}
                    >
                        <div className="row">

                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                        <img src={Forteen} alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Free Trial</h3>
                                        <span>For the basics</span>
                                    </div>
                                    <span className="price">
                                        $0<span>/Year</span>
                                    </span>
                                    <ul className="benifits">
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />7 Days free trial
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />2 platform of your
                                                choice
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                10 GB Dedicated Hosting free
                                            </p>
                                        </li>
                                        <li className="exclude">
                                            <p>
                                                <i className="icofont-close-circled" />
                                                Unlimited updates
                                            </p>
                                        </li>
                                        <li className="exclude">
                                            <p>
                                                <i className="icofont-close-circled" />
                                                Live support
                                            </p>
                                        </li>
                                    </ul>
                                    <Link to="#" className="btn btn_main">
                                        BUY NOW <i className="icofont-arrow-right" />
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="pricing_block highlited_block">
                                    <div className="icon">
                                        <img src={Fifteen} alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Unlimited</h3>
                                        <span>For the professionals</span>
                                    </div>
                                    <span className="price">
                                        $999<span>/Year</span>
                                    </span>
                                    <ul className="benifits">
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />7 Days free trial
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />2 platform of your
                                                choice
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                10 GB Dedicated Hosting free
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                Unlimited updates
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                Live support
                                            </p>
                                        </li>
                                    </ul>
                                    <Link to="#" className="btn btn_main">
                                        BUY NOW <i className="icofont-arrow-right" />
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                        <img src={Sixteen} alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                            <span className="dot_anim" />
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Premium</h3>
                                        <span>For small team</span>
                                    </div>
                                    <span className="price">
                                        $555<span>/Year</span>
                                    </span>
                                    <ul className="benifits">
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />7 Days free trial
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />2 platform of your
                                                choice
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                10 GB Dedicated Hosting free
                                            </p>
                                        </li>
                                        <li className="include">
                                            <p>
                                                <i className="icofont-check-circled" />
                                                Unlimited updates
                                            </p>
                                        </li>
                                        <li className="exclude">
                                            <p>
                                                <i className="icofont-close-circled" />
                                                Live support
                                            </p>
                                        </li>
                                    </ul>
                                    <Link to="#" className="btn btn_main">
                                        BUY NOW <i className="icofont-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Doublethree
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
// import one from '../../assets/images/custom/partners/benway.png'
// import two from '../../assets/images/custom/partners/coban.png'
// import three from '../../assets/images/custom/partners/concox2.png'
// import four from '../../assets/images/custom/partners/iTrack.png'
// import five from '../../assets/images/custom/partners/sinotrack.png'
// import six from '../../assets/images/custom/partners/teltonika.png'
// import seven from '../../assets/images/custom/partners/benway.png'
// import eight from '../../assets/images/custom/partners/coban.png'

import one from '../../assets/tech_partner/85310.png'
import two from '../../assets/tech_partner/Google_Cloud_logo.svg.png'
import three from '../../assets/tech_partner/Grameenphone-Logo.png'
import four from '../../assets/tech_partner/IMG_Logo_Iridium_Black-Gold.png'
import five from '../../assets/tech_partner/Meitrack-website-logo.jpg'
import six from '../../assets/tech_partner/Teltonika-logo.png'
import seven from '../../assets/tech_partner/dfd.png'
import eight from '../../assets/tech_partner/dsfds.png'
import nine from '../../assets/tech_partner/i-Marine-Logo.png'
import ten from '../../assets/tech_partner/nosotros-dispositivos-gps-concox-2.png'
import OwlCarousel from 'react-owl-carousel'

function Trusted() {
  let satting = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  }

  const companyLogos = [
    { image: one },
    { image: two },
    { image: three },
    { image: four },
    { image: five },
    { image: six },
    { image: seven },
    { image: eight },
    { image: nine },
    { image: ten },
  ]

  return (
    <>
      <section className="row_am trusted_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <h2>Technology Partner</h2>
            <p>We Work with world top Technology company.</p>
          </div>

          <div
            className="company_logos"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <OwlCarousel
              {...satting}
              id="company_slider"
              className="owl-carousel owl-theme"
            >
              {companyLogos.map((logo, index) => (
                <div key={index} className="item">
                  <div className="logo">
                    <img
                      src={logo.image}
                      alt="image"
                      height={40}
                      width={'auto'}
                    />
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default Trusted

import React from 'react'
import Error404 from '../../Page/Error404'

function Main() {
  return (
    <>
      <Error404/>
    </>
  )
}

export default Main

/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'

function Map() {
  return (
    <>
      <section className="row_am map_section">
        <div className="container">
          <div className="map_inner">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.8453533707!2d90.37262368627636!3d23.752893523667517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9e14a57fb01%3A0x7b72f7562c1ccdf7!2sSafety%20GPS%20Tracker!5e0!3m2!1sen!2sbd!4v1686208019783!5m2!1sen!2sbd"
              style={{ border: 0, width: '100%', height: 510 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default Map

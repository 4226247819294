import React from 'react'
import Header from '../../components/Menabout/Header'
import Bredcom from '../Bredcom/Main'
import Service from '../Mentesting/Service'
import Customer from '../Mentesting/Customer'
import Support from '../Mentesting/Support'

function main() {
  return (
    <>
        <div className="inner_page_block">
          <Header/>
          <Bredcom title={"Home"} subtitle={"Testimonials"} same={"What Our Client Says"}/>
        </div>
        <Service/>
        <Customer/>
        <Support/>
    </>
  )
}

export default main

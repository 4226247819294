import React from 'react'
// import one from '../../assets/images/custom/features/1.png'
// import two from '../../assets/images/custom/features/2.jpeg'
// import three from '../../assets/images/custom/features/3.png'
// import four from '../../assets/images/custom/features/4.png'
// import five from '../../assets/images/custom/features/5.webp'
// import six from '../../assets/images/custom/features/6.png'
// import seven from '../../assets/images/custom/features/7.png'
// import eight from '../../assets/images/custom/features/8.png'
// import nine from '../../assets/images/custom/features/9.png'
// import ten from '../../assets/images/custom/features/10.png'
// import eleven from '../../assets/images/custom/features/11.png'
// import twelve from '../../assets/images/custom/features/12.png'
import one from '../../assets/clients/1200.png'
import two from '../../assets/clients/13_26_42.png'
import three from '../../assets/clients/BRTC Logo.png'
import four from '../../assets/clients/TheACMELaboratoriesL.png'
import five from '../../assets/clients/Tiger CEMENT Logo copy.png'
import six from '../../assets/clients/bangladesh-govt.png'
import seven from '../../assets/clients/dfdfdf.png'
import eight from '../../assets/clients/fsffd.png'
import nine from '../../assets/clients/madina-group.png'
import ten from '../../assets/clients/sdf.png'
import eleven from '../../assets/clients/sfvfv.png'
import twelve from '../../assets/clients/trucklagbe-logo.jpg'

function Integration() {
  return (
    <>
      <section className="row_am integration_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2>Our Clients</h2>
            <p>
              We provide a time-worthy business solution to any type of
              business. We Served Bangladesh top Government and Corporate group.
            </p>
          </div>
          <div className="platforms_list">
            <div className="row">
              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={one} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Android, IOS App and Web Platform</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={two} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>BRTA Paper Update Alert</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={three} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Engine off/on from App</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={four} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Daily Milage Report</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={five} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Car Inside voice Monitoring</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={six} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Different Maps</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={seven} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Fuel Monitoring</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={eight} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Route History Playback</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={nine} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Google Street View</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={ten} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>10 Second Update</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={eleven} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Ignition Alert</p>
                  </div> */}
                </div>
              </div>

              <div
                className="col-lg-2 col-md-3 col-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="list_block">
                  <div className="icon">
                    <img src={twelve} alt="image" width={80} />
                  </div>
                  {/* <div className="caption">
                    <p>Maintenance Alert</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Integration

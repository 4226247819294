import React from 'react'
import One from '../../assets/images/new/mail.png'
import Two from '../../assets/images/new/location.png'
import Three from '../../assets/images/new/phone.png'
import { Link } from 'react-router-dom'

const data = [
  {
    img: One,
    title: 'Chat to sales',
    description: 'Let’s discuss with our sales team',
    link: 'mailto:info@impressivebd.com',
    btnText: 'info@impressivebd.com'
  },
  {
    img: Two,
    title: 'Visit our office',
    description: 'Reach us to our offcie and meest us',
    link: '#',
    btnText: '5th Floor, 100/A Shukrabad Dhanmondi, Dhaka-1000'
  },
  {
    img: Three,
    title: 'Call us',
    description: 'Call us directly with sales team',
    link: 'tel:+8801912180008',
    btnText: '+88 019-1218-0008'
  }
]

function List() {
  return (
    <section className="row_am contact_list_section">
      <div className="container">
        <div className="contact_list_inner"
          data-aos="fade-up"
          data-aos-duration={2000}
          data-aos-delay={100}
        >
          {data.map((item, index) => (
            <div key={index} className="c_list_card">
              <div className="icons">
                <img src={item.img} alt="image" />
                <div className="dot_block">
                  <span className="dot_anim" />
                  <span className="dot_anim" />
                  <span className="dot_anim" />
                </div>
              </div>
              <div className="inner_text">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <Link to={item.link} className="text_btn">
                  {item.btnText}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default List

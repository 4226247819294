import React from 'react'
import Header from '../../Menabout/Header'
import Bredcom from '../../Bredcom/Main'
import Pricing from '../../Page/Pricing'


function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom title={"Home"} subtitle={"Faqs"} same={"Faqs"}/>
      </div>

      <Pricing/>
    </>
  )
}

export default Main

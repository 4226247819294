import React from 'react'
import Header from '../../Menabout/Header'
import Bredcom from '../../Bredcom/Main'
import Tripalone from '../../List/Tripalone'
import Coustmer from '../../Menhome/Customer'
import Doublethree from '../../List/Doublethree'
import Double from '../../List/Double'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom title={"Home"} subtitle={"Our Services"} same={"Services List 2"}/>
      </div>

      <Tripalone />
      <Coustmer />
      <Doublethree />
      <Double />
    </>
  )
}

export default Main

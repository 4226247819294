import React from 'react'
import { Link } from 'react-router-dom'

function Second({title, subtitle, same}) {
    return (
        <>
            <div className="bread_crumb" data-aos="fade-in" data-aos-duration={2000} data-aos-delay={100}>
                <div className="container">
                    <div className="bred_text">
                        <h1>{same}</h1>
                        <ul>
                            <li>
                                <Link to="/">{title}</Link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <Link to="/service-list-1">{subtitle}</Link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <Link to="/service-detail">{same}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Second
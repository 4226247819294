import React from 'react'
import { Link } from 'react-router-dom'

function Support() {
  return (
    <>
      <section className="need_section" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
        <div className="container">
          <div className="need_block">
            <div className="need_text">
              <div className="section_title">
                <h2>Need support ? contact our team</h2>
                <p><i className="icofont-clock-time"></i> Sat - Thu: 10 am to 6 pm</p>
              </div>
            </div>
            <div className="need_action">
              <Link to="tel:01713546386" className="btn"><i className="icofont-phone-circle"></i> 01912180008</Link>
              <Link to="#faqBlock" className="faq_btn">Read The FAQ </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Support

import React from 'react'
import Header from '../../Menabout/Header'
import Bredcom from '../../Bredcom/Main'
import Pricing from '../../Page/Pricing'
import Double from '../../List/Double'
import Doublethree from '../../List/Doublethree'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom title={"Home"} subtitle={"Our Pricing"} same={"Best Pricing"}/>
      </div>

      <Doublethree />
      <Pricing />
      <Double />
      
    </>
  )
}

export default Main

import React from 'react'
import Signin from '../../Page/Signin'

function Main() {
  return (
    <>
      <Signin/>
    </>
  )
}

export default Main

import React from 'react'
import Header from '../../Menabout/Header'
import Bredcom from '../../Bredcom/Main'
import Double from '../../List/Double'
import Doublefirst from '../../List/Doublefirst'
import Doublesecond from '../../List/Doublesecond'
import Customer from '../../Menhome/Customer'
import Doublethree from '../../List/Doublethree'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom title={"Home"} subtitle={"Our Services"} same={"Services List 1"}/>
      </div>

     <Doublefirst/>
     <Doublesecond/>
     <Customer/>
     <Doublethree/>
     <Double/>

    </>

  )
}

export default Main

import React from 'react'
import { Link } from 'react-router-dom'


function Double() {

  
  return (
    <>
        <section
          className="need_section innerpage_needsection"
          data-aos="fade-in"
          data-aos-duration={1500}
          data-aos-delay={100}
        >
          <div className="container">
            <div className="need_block">
              <div className="need_text">
                <div className="section_title">
                  <h2>Need support ? contact our team</h2>
                  <p>
                    <i className="icofont-clock-time" /> Mon - Fri: 9 am to 5 am
                  </p>
                </div>
              </div>
              <div className="need_action">
                <Link to="tel:01713546386" className="btn">
                  <i className="icofont-phone-circle" /> 01713546386
                </Link>
                <Link to="/faq" className="faq_btn">
                  Read The FAQ{" "}
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
  )
}

export default Double

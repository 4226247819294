import React from 'react'
import { Link } from 'react-router-dom'
import One from '../../assets/images/new/orange_play.png'
import Two from '../../assets/images/new/abt_01.png'
import Three from '../../assets/images/new/abt_02.png'
import Four from '../../assets/images/new/abt_03.png'
import NumberCounter from 'number-counter'

function About() {
  return (
    <>
      <section className="about_us_page_section">
        <div className="dotes_anim_bloack">
          <div className="dots dotes_1" />
          <div className="dots dotes_2" />
          <div className="dots dotes_3" />
          <div className="dots dotes_4" />
          <div className="dots dotes_5" />
          <div className="dots dotes_6" />
          <div className="dots dotes_7" />
          <div className="dots dotes_8" />
        </div>
        <div className="container">
          <div className="about_block">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div
                  className="section_title text-center"
                  data-aos="fade-in"
                  data-aos-duration={1500}
                >
                  <h2>About US</h2>
                  <p>
                    Impressive Security Ltd. was established in the very
                    beginning of 2012, and was registered as a company in the
                    same year on September. Impressive Security Ltd is a 100%
                    private limited company under the Company Act. 2004 and
                    operated. From humble beginnings Impressive Security Ltd.
                    has grown from a "small operation" into an organization that
                    boasts a modern range of security products and services to
                    accommodate the ever changing daily needs of human being and
                    the environment in which we live. Built on the premise that
                    security and safety in our country is a downward spiral,
                    Impressive strives to be a major contributing factor in
                    correcting this trend.
                  </p>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div
                  className="abt_img_block"
                  data-aos="fade-in"
                  data-aos-duration={1500}
                >
                  <Link
                    data-aos="fade-up"
                    data-aos-duration={1500}
                    className="popup-youtube play-button"
                    data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                    data-toggle="modal"
                    data-target="#myModal"
                    title="XJj2PbenIsU"
                  >
                    <div className="play_btn">
                      <img src={One} alt="image" />
                      <div className="waves-block">
                        <div className="waves wave-1" />
                        <div className="waves wave-2" />
                        <div className="waves wave-3" />
                      </div>
                    </div>
                  </Link>
                  <div className="top_img">
                    <img src={Two} alt="image" />
                    <img src={Three} alt="image" />
                  </div>
                  <div className="bottom_img">
                    <img src={Four} alt="image" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div id="counter">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <span className="counter-value" data-count={120}>
                        <span
                          className="counter-value"
                          // data-count={20000}
                          // end={20000}
                          // delay={0.5}
                        >
                          20K
                        </span>
                      </span>
                      <span>+</span>
                    </p>
                    <p>Subscribed Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <span className="counter-value" data-count={120}>
                        <NumberCounter
                          className="counter-value"
                          data-count={50}
                          end={50}
                          delay={2}
                        >
                          0
                        </NumberCounter>
                      </span>
                      <span>+</span>
                    </p>
                    <p>Projects</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <span className="counter-value" data-count={120}>
                        <span
                          className="counter-value"
                          // data-count={20000}
                          // end={20000}
                          // delay={0.5}
                        >
                          100K
                        </span>
                      </span>
                      <span>+</span>
                    </p>
                    <p>Device Run on our Server</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <span className="counter-value" data-count={120}>
                        <span
                          className="counter-value"
                          // data-count={20000}
                          // end={20000}
                          // delay={0.5}
                        >
                          50K
                        </span>
                      </span>
                      <span>+</span>
                    </p>
                    <p>App Downloads</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About

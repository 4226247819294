import React from 'react'
import One from '../../assets/images/new/service-1.svg'
import Two from '../../assets/images/new/service-2.svg'
import Three from '../../assets/images/new/service-3.svg'
import Four from '../../assets/images/new/service-4.svg'
import Five from '../../assets/images/new/service-5.svg'
import Six from '../../assets/images/new/service-6.svg'
import { Link } from 'react-router-dom'

function Doublefirst() {

    return (
        <>
            <section className="row_am service_list_section">
                <div className="container">
                    <div className="section_title"
                        data-aos="fade-up"
                        data-aos-duration={1500}
                        data-aos-delay={100} >

                        <h2>Our Featured Services </h2>

                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typese tting
                            indus <br /> orem Ipsum has beenthe standard dummy text ever since
                        </p>
                    </div>
                    <div
                        className="service_list_inner"
                        data-aos="fade-up"
                        data-aos-duration={2000}
                        data-aos-delay={100}
                    >


                        <div className="s_list_card">
                            <div className="icons">
                                <img src={One} alt="image" />
                                <div className="dot_block">
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                </div>
                            </div>
                            <div className="inner_text">
                                <h3>Software Development</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text ofthe printing and type setting
                                    indus ideas orem Ipsum has beenthe.
                                </p>
                                <Link to="/service-detail" className="btn text_btn">
                                    DISCOVER MORE <i className="icofont-arrow-right" />
                                </Link>
                            </div>
                        </div>

                        <div className="s_list_card">
                            <div className="icons">
                                <img src={Two} alt="image" />
                                <div className="dot_block">
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                </div>
                            </div>
                            <div className="inner_text">
                                <h3>Maintenance</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text ofthe printing and type setting
                                    indus ideas orem Ipsum has beenthe.
                                </p>
                                <Link to="/service-detail" className="btn text_btn">
                                    DISCOVER MORE <i className="icofont-arrow-right" />
                                </Link>
                            </div>
                        </div>

                        <div className="s_list_card">
                            <div className="icons">
                                <img src={Three} alt="image" />
                                <div className="dot_block">
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                </div>
                            </div>
                            <div className="inner_text">
                                <h3>Bug Solving</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text ofthe printing and type setting
                                    indus ideas orem Ipsum has beenthe.
                                </p>
                                <Link to="/service-detail" className="btn text_btn">
                                    DISCOVER MORE <i className="icofont-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="service_list_inner" data-aos="fade-up"
                        data-aos-duration={2000}
                        data-aos-delay={100} >

                        <div className="s_list_card">
                            <div className="icons">
                                <img src={Four} alt="image" />
                                <div className="dot_block">
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                </div>
                            </div>
                            <div className="inner_text">
                                <h3>Customized Solution</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text ofthe printing and type setting
                                    indus ideas orem Ipsum has beenthe.
                                </p>
                                <Link to="/service-detail" className="btn text_btn">
                                    DISCOVER MORE <i className="icofont-arrow-right" />
                                </Link>
                            </div>
                        </div>

                        <div className="s_list_card">
                            <div className="icons">
                                <img src={Five} alt="image" />
                                <div className="dot_block">
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                </div>
                            </div>
                            <div className="inner_text">
                                <h3>UI/UX Design</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text ofthe printing and type setting
                                    indus ideas orem Ipsum has beenthe.
                                </p>
                                <Link to="/service-detail" className="btn text_btn">
                                    DISCOVER MORE <i className="icofont-arrow-right" />
                                </Link>
                            </div>
                        </div>

                        <div className="s_list_card">
                            <div className="icons">
                                <img src={Six} alt="image" />
                                <div className="dot_block">
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                    <span className="dot_anim" />
                                </div>
                            </div>
                            <div className="inner_text">
                                <h3>24/7 Free Support</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text ofthe printing and type setting
                                    indus ideas orem Ipsum has beenthe.
                                </p>
                                <Link to="/service-detail" className="btn text_btn">
                                    DISCOVER MORE <i className="icofont-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Doublefirst
import React from 'react';

const faqs = [
  {
    question: 'How can I pay?',
    answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry .',
  },
  {
    question: 'How to setup account?',
    answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  },
  {
    question: 'What is process to get refund?',
    answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  },
  {
    question: 'What is process to get refund?',
    answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  },
];

function Faq() {
  return (
    <section id="faqBlock" className="row_am faq_section">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
          <h2>
            <span>FAQ</span> - Frequently Asked Questions
          </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typese tting <br />{" "}
            indus orem Ipsum has beenthe standard dummy.
          </p>
        </div>

        <div className="faq_panel">
          <div className="accordion" id="accordionExample">
            {faqs.map((faq, index) => (
              <div className="card" data-aos="fade-up" data-aos-duration={1500} key={index}>
                <div className="card-header" id={`heading${index}`}>
                  <h2 className="mb-0">
                    <button type="button" className="btn btn-link" data-toggle="collapse" data-target={`#collapse${index}`}>
                      <i className="icon_faq icofont-plus" /> {faq.question}
                    </button>
                  </h2>
                </div>
                <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`} data-parent="#accordionExample">
                  <div className="card-body">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;

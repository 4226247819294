import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import About from '../components/About/Main'
import Test from '../components/Testing/main'
import Contact from '../components/Contact/Main'
import Bloglist from '../components/Menblog/Bloglist/Main'
import Singleblog from '../components/Menblog/Singleblog/Main'
import Double from '../components/Menlist/Double/Main'
import Triple from '../components/Menlist/Triple/Main'
import Forth from '../components/Menlist/Forth/Main'
import Pricing from '../components/Menpage/Pricing/Main'
import Signin from '../components/Menpage/Signin/Main'
import Signup from '../components/Menpage/Signup/Main'
import Error404 from '../components/Menpage/Error404/Main'
import Faq from '../components/Menpage/Faq/Main'
import VehicleTracking from '../components/vehicle-tracking/Bloglist/Main'
function Index() {
  const [FooterShow, setfooterShow] = useState(false)
  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    if (path === "/sign-in" || path === "/sign-up" || path === "/error") {
      setfooterShow(true)
    } else {
      setfooterShow(false)
    }
    window.scroll(0, 0)
  }, [path]);

  return (
    <>

      <div className='page_wrapper'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/testimonial" element={<Test />} />
          <Route path="/blog-list" element={<Bloglist />} />
          <Route path="/vehicle-tracking" element={<VehicleTracking />} />
          <Route path="/blog-detail" element={<Singleblog />} />
          <Route path="/service-list-1" element={<Double />} />
          <Route path="/service-list-2" element={<Triple />} />
          <Route path="/service-detail" element={<Forth />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/sign-in" element={<Signin />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/error" element={<Error404 />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {!FooterShow && <Footer />}
      </div>
    </>
  )
}

export default Index
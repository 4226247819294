import React from 'react'
import Header from '../Menabout/Header'
import Bredcom from '../Bredcom/Main'
import Service from '../Menabout/Service'
import About from '../Menabout/About'
import Solution from '../Menabout/Solution'
 import Experience from '../Menabout/Experience'
import Customer from '../Menabout/Customer'
import Freetrial from '../Menhome/Freetrial'


function Main() {
  return (
    <>
      <div className="inner_page_block">
          <Header/>
          <Bredcom title={"Home"} subtitle={"About"} same={"About us"}/>
      </div>

      <Service/>
      <About/>
      {/* <Solution/>
      <Experience/>
      <Customer/> */}
      {/* <Freetrial/> */}

    </>
  )
}

export default Main

import React from 'react'
import { Link } from 'react-router-dom'
import One from '../../assets/service-img.50df2a79a7fd44d3494b.png'

function Fourthone() {
  return (
    <>
      <section className="service_detail_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service_left_side">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                >
                  <h2>Software Development Services</h2>
                  <p>
                    Unlock our capabilities of custom software development
                    services, product development and quality assurance for
                    businesses of all sizes and shapes. Being a leading software
                    development company we offer full-cycle development services
                    enabling businesses to be agile, futuristic and
                    high-performing.
                  </p>
                </div>
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                >
                  <img src={One} alt="image" />
                </div>
                <h3 data-aos="fade-up" data-aos-duration={1500}>
                  Benifits of our services
                </h3>
                <p data-aos="fade-up" data-aos-duration={1500}>
                  As a leading software development service provider, we excel
                  in delivering the best-suited software solution as per the
                  custom needs, be it small startups in their ideation phase or
                  mid-size businesses focusing on growth or large enterprises
                  actively optimizing business processes across varied
                  organizations and industries.
                </p>
                <ul
                  className="list_block"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <li>
                    <h3>Carefully designed</h3>
                    <p>
                      Our Team care about you and Design any software with
                      latest and suitable technology .
                    </p>
                  </li>
                  <li>
                    <h3>24/7 live support</h3>
                    <p>We give 24/7 support to our customer.</p>
                  </li>
                  <li>
                    <h3>Expert developer</h3>
                    <p>We have country best expert developer team.</p>
                  </li>
                  <li>
                    <h3>Ontime delivery</h3>
                    <p>
                      We know our clients time is very important. We always try
                      deliver your project on time.
                    </p>
                  </li>
                </ul>
                <h3 data-aos="fade-up" data-aos-duration={1500}>
                  How we build your software
                </h3>
                <p data-aos="fade-up" data-aos-duration={1500}>
                  Scrum Methodology is led by Scrum Master taking on all the
                  responsibilities like task prioritization , resource
                  availability, time management, planning, creating blueprints,
                  meeting deadlines and many other tasks. Our Scrum team also
                  ensures the optimal use of time and money.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service_right_side">
                <div
                  className="service_list_panel"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <h3>Services</h3>
                  <ul className="service_list">
                    <li>
                      <Link to="/service-detail" className="active">
                        Software Development
                      </Link>
                    </li>
                    <li>
                      <a href="https://safetygpstracker.com.bd/">
                        Vehicle Tracking
                      </a>
                    </li>
                    <li>
                      <a href="https://tripfindy.com/">Online Travel Agency</a>
                    </li>
                  </ul>
                </div>
                <div
                  className="side_contact_block"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="icon">
                    <i className="icofont-live-support" />
                  </div>
                  <h3>Let’s work together</h3>
                  <Link to="/contact" className="btn btn_main">
                    CONTACT US <i className="icofont-arrow-right" />
                  </Link>
                  <p>
                    <Link to="tel:01713546386">
                      <i className="icofont-phone-circle" /> 01713546386
                    </Link>
                  </p>
                </div>
                <div className="action_btn">
                  <Link
                    to="images/pdf-brochure.pdf"
                    target="blank"
                    className="btn"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <span>
                      <i className="icofont-file-pdf" />{' '}
                    </span>
                    <p>Company Profile</p>
                  </Link>
                  <Link
                    to="images/pdf-brochure.pdf"
                    target="blank"
                    className="btn"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <span>
                      <i className="icofont-file-powerpoint" />{' '}
                    </span>
                    <p>Download PPT</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Fourthone

import React from 'react'
import Bloglist from '../../Blog/Bloglist'
import Header from '../../Menabout/Header'
import Bredcom from '../../Bredcom/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
          <Header/>
          <Bredcom title={"Home"} subtitle={"Blog list"} same={"Blog list classic"}/>
        </div>

       <Bloglist/>

       </>
    

  )
}

export default Main

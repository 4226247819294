/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import Two from '../../assets/images/new/features_icon_02.svg'
import Three from '../../assets/FB_IMG_1686208418307.jpg'

import { Link } from 'react-router-dom'

function Service() {
  const features = [
    {
      icon:
        'https://safetygpstracker.com.bd/en/wp-content/uploads/2021/11/SAfety-Fss.png',
      title: 'Vehicle Tracking',
      description:
        'A Modern day Vehicle Tracking system that use GPS technology for locating the vehicle.',
      details: 'https://safetygpstracker.com.bd/',
    },
    {
      icon: Two,
      title: 'Software Development',
      description: 'One Stop Solution" for web & mobile app development',
      details: '/service-detail',
    },
    {
      icon: Three,
      title: 'Online Travel Agency',
      description:
        'Means of connecting to global network. View details for coverage area.',
      details: 'https://tripfindy.com/',
    },
  ]
  return (
    <>
      <section className="review_section mb-5">
        <div className="container">
          <div className="row">
            {features.map((feature, index) => (
              <div className="col-lg-4" key={index}>
                <div
                  className="review_box d-flex flex-wrap justify-content-center"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                >
                  <div className="icon my-2">
                    <img
                      src={feature.icon}
                      alt="image"
                      height={index === 1 ? 150 : 120}
                      style={{ padding: '30px 0' }}
                    />
                  </div>
                  <div className="text my-2 text-center">
                    <h3 className="text-center mb-3">{feature.title}</h3>
                    <p className="text-center">{feature.description}</p>
                    <Link to={feature?.details} className="btn text_btn">
                      DISCOVER MORE <i className="icofont-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Service

import React from 'react'
import Logo from '../../assets/isl.png'
import Contact1 from '../../assets/images/new/contact_01.png'
import Contact2 from '../../assets/images/new/contact_02.png'
import Contact3 from '../../assets/images/new/contact_03.png'
import { Link } from 'react-router-dom'

function Main() {
  return (
    <>
      <footer>
        <div className="top_footer" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="abt_side">
                  <div className="logo">
                    <img src={Logo} alt="image" />
                  </div>
                  <p>
                    Impressive Security Ltd. was established in the very
                    beginning of 2012, and was registered as a company in the
                    same year on September. Impressive Security Ltd is a 100%
                    private limited company under the Company Act. 2004 and
                    operated.
                  </p>
                  {/* <div className="news_letter_block">
                    <form action="submit">
                      <div className="form-group">
                        <input
                          type="email"
                          placeholder="Enter your email"
                          className="form-control"
                        />
                        <button className="btn">
                          <i className="icofont-paper-plane" />
                        </button>
                      </div>
                    </form>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12">
                <div className="links">
                  <h3>Useful Links</h3>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/service-detail">Service Details</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12">
                <div className="links">
                  <h3>Help &amp; Suport</h3>
                  <ul>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    {/* <li>
                      <Link to="/faq">FAQs</Link>
                    </li>
                    <li>
                      <Link to="#">How it works</Link>
                    </li> */}
                    <li>
                      <Link to="#">Terms &amp; conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12">
                <div className="try_out">
                  <h3>Contact us</h3>
                  <ul>
                    <li>
                      <span className="icon">
                        <img src={Contact1} alt="image" />
                      </span>
                      <div className="text">
                        <p>5th Floor, 100/A Shukrabad Dhanmondi, Dhaka-1000</p>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <img src={Contact2} alt="image" />
                      </span>
                      <div className="text">
                        <p>
                          Call us{' '}
                          <Link to="tel:+8801912180008">01912180008</Link>
                        </p>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <img src={Contact3} alt="image" />
                      </span>
                      <div className="text">
                        <p>
                          Email us{' '}
                          <Link to="mailto:info@impressivebd.com">
                            info@impressivebd.com
                          </Link>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_footer">
          {/* container start */}
          <div className="container">
            {/* row start */}
            <div className="row">
              <div className="col-md-6">
                <p>© Copyrights 2023. All rights reserved.</p>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <ul className="social_media">
                  <li>
                    <Link to="#">
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-pinterest" />
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className="col-md-4">
                                <p className="developer_text">
                                    Design &amp; developed by{" "}
                                    <Link
                                        to="https://themeforest.net/user/kalanidhithemes"
                                        target="blank"
                                    >
                                        Kalanidhi Themes
                                    </Link>
                                </p>
                            </div> */}
            </div>
            {/* row end */}
          </div>
          {/* container end */}
        </div>

        <div className="go_top" id="Gotop">
          <span>
            <i className="icofont-arrow-up" />
          </span>
        </div>
      </footer>
    </>
  )
}

export default Main

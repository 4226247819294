import React from 'react'
import Header from '../Menabout/Header'
import Bredcom from '../Bredcom/Main'
import List from '../Mencontact/List'
import Start from '../Mencontact/Start'
import Map from '../Mencontact/Map'

function Main() {
  return (
    <>
        <div className="inner_page_block" >
            <Header/>
            <Bredcom title={"Home"} subtitle={"Contact Us"} same={"Contact Us"}/>
        </div>
        <List/>
        <Start/>
        <Map/>
    </>
  )
}

export default Main

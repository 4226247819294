/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/isl.png'

function Main() {
  const [headerScroll, setheaderScroll] = useState(false)
  const [click, setClick] = useState(false)
  useEffect(() => {
    const fixedHeader = () => {
      if (window.pageYOffset > 150) {
        setheaderScroll(true)
      } else {
        setheaderScroll(false)
      }
    }
    window.addEventListener('scroll', fixedHeader)
  }, [])
  return (
    <>
      <header className={headerScroll ? 'fixed fix_style' : 'fixed'}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="image" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <span
                  className={`toggle-wrap ${click === true ? 'active' : ''}`}
                  onClick={() => {
                    setClick(!click)
                  }}
                >
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item  active">
                  <span className="color-item">
                    <Link className="nav-link " to="/">
                      Home
                    </Link>
                  </span>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About us
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/testimonial">
                    Testimonial
                  </Link>
                </li> */}

                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#">
                    Services
                  </Link>
                  <span className="drp_btn ml-2">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <a href="https://safetygpstracker.com.bd">
                          Vehicle Tracking
                        </a>
                      </li>
                      <li>
                        <Link to="/service-detail">Software Development</Link>
                      </li>

                      <li>
                        <a href="https://tripfindy.com">Online Travel Agency</a>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#">
                    Blog{" "}
                  </Link>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/blog-list">Blog List</Link>
                      </li>
                      <li>
                        <Link to="/blog-detail">Single Blog</Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
                {/* <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#">
                    Pages{" "}
                  </Link>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/pricing">Pricing </Link>
                      </li>
                      <li>
                        <Link to="/sign-in">Sign In </Link>
                      </li>
                      <li>
                        <Link to="/sign-up">Sign Up </Link>
                      </li>
                      <li>
                        <Link to="/error">Error 404 </Link>
                      </li>
                      <li>
                        <Link to="/faq">Faq </Link>
                      </li>
                    </ul>
                  </div>
                </li> */}

                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link dark_btn" to="/contact">
                    TRY IT FREE <i className="icofont-arrow-right" />
                  </Link>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Main

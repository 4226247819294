/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import one from '../../assets/approvedBy/brtc.png'
import two from '../../assets/approvedBy/civil.png'
import three from '../../assets/approvedBy/VTSPAB.png'
import OwlCarousel from 'react-owl-carousel'

function ApprovedBy() {
  let satting = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  }

  const companyLogos = [{ image: one }, { image: two }, { image: three }]

  return (
    <>
      <section className="row_am ApprovedBy_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <h2>Accreditation By</h2>
            <p>
              Accredited From BTRC For Vehicle Tracking, Civil Aviation for OTA
              also from VTSPAB.
            </p>
          </div>

          <div
            className="company_logos  d-flex justify-content-center "
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            {/* <OwlCarousel {...satting} id="company_slider" className="owl-carousel owl-theme"> */}
            {companyLogos.map((logo, index) => (
              <div key={index} className="item">
                <div className="logo">
                  <img
                    src={logo.image}
                    alt="image"
                    height={80}
                    width={'auto'}
                    style={{ margin: '5px 20px' }}
                  />
                </div>
              </div>
            ))}
            {/* </OwlCarousel> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default ApprovedBy

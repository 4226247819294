import React, { useState }  from 'react'
import one from '../../assets/images/play_icon.png'
import two from '../../assets/images/new/start-free-side-img.png'
import { Link } from 'react-router-dom'


function Freetrial() {

  const [ytShow, setytShow] = useState(false);

  return (
    <>
      <section className="free_trial_section"
        data-aos="fade-in"
        data-aos-duration={1500}>
        <div className="free_inner">
          <div className="text">
            <div className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}>
              <h2>Start Your 14-Day Free Trial</h2>
              <p>
                Instant free download from apple and play store orem Ipsum is simply
                dummy text of the printing.
              </p>
            </div>
            <ul data-aos="fade-up" data-aos-duration={1500}>
              <li>
                <p>
                  <i className="icofont-check-circled" />
                  Free 14-day trial
                </p>
              </li>
              <li>
                <p>
                  <i className="icofont-check-circled" />
                  No credit card required
                </p>
              </li>
              <li>
                <p>
                  <i className="icofont-check-circled" />
                  Support 24/7
                </p>
              </li>
              <li>
                <p>
                  <i className="icofont-check-circled" />
                  Cancel anytime
                </p>
              </li>
            </ul>
            <div className="start_and_watch"
              data-aos="fade-up"
              data-aos-duration={1500} >
              <Link to="/contact" className="btn btn_main">
                GET STARTED <i className="icofont-arrow-right" />
              </Link>
              <Link className="popup-youtube play-button" onClick={() => setytShow(true)} data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU" >
                <div className="play_btn">
                  <img src={one} alt="image" />
                  <div className="waves-block">
                    <div className="waves wave-1" />
                    <div className="waves wave-2" />
                    <div className="waves wave-3" />
                  </div>
                </div>
                <span>WATCH PROMO</span>
              </Link>
            </div>
          </div>
          <div className="side_img">
            <img src={two} alt="image" />
          </div>
        </div>
      </section>

      {ytShow &&
        <div class="modal fade youtube-video" id="myModal" tabindex="-1" role="dialog" >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <button id="close-video" type="button" class="button btn btn-default text-right" data-dismiss="modal" onClick={() => setytShow(false)}>
              <i class="icofont-close-line-circled"></i>
            </button>
            <div class="modal-body">
              <div id="video-container" class="video-container">
                <iframe id="youtubevideo" width="640" height="360" allowfullscreen></iframe>
              </div>
            </div>
            <div class="modal-footer">
            </div>
          </div>
        </div>
      </div>
      }

    
    </>
  )
}

export default Freetrial
